export default {
	data: {
		selectedImageIndex: undefined,
		openstreetmap: false
	},
	beforeMount: function () {

		this.checkOsmConsent();

		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},

	mounted: function () {
		window.addEventListener('ccm19WidgetClosed', (event) => {
			this.checkOsmConsent();
		});
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['website', 'v-office.com']).join('@');
		},
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},
		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		}
	},
	methods: {

		searchTab: function () {

			document.getElementById('obj-tab').classList.remove('active');
			document.getElementById('search-tab').classList.remove('active');
			document.getElementById('search-obj-form').style.display = 'none';
			document.getElementById('search-form').style.display = 'flex';
			document.getElementById('search-tab').classList.add('active');

		},
		objectTab: function () {

			document.getElementById('obj-tab').classList.remove('active');
			document.getElementById('search-tab').classList.remove('active');
			document.getElementById('search-form').style.display = 'none';
			document.getElementById('search-obj-form').style.display = 'flex';
			document.getElementById('obj-tab').classList.add('active');

		},

		checkOsmConsent: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			const allowOpenstreet = this.getCookie(cookieName);
			if (allowOpenstreet) {
				this.openstreetmap = true;
			} else {
				const data = CCM.acceptedEmbeddings;
				const targetName = "OpenStreetMap";
				const entry = data.find(item => item.name === targetName);

				if (entry) {
					this.openstreetmap = true;
				} else {
					this.openstreetmap = false;
				}
			}
		},

		getCookie: function (name) {
			const nameEQ = name + "=";
			const ca = document.cookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
			}
			return null;
		},

		setCookie: function (name, value, days) {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			const expires = "expires=" + date.toUTCString();
			document.cookie = name + "=" + value + ";" + expires + ";path=/";
		},

		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName, true, VOFFICE.settings.osmCookieDays);
			this.openstreetmap = true;
		},

		showOpenstreetmap: function () {
			this.openstreetmap = true;
		},

		getUnitSearchTarget: function (id) {
			return 'unit_' + id;
		},

		unitImages: function (string) {
			//console.log(string);
		},

		toggleAccordion(event) {
			// Get the clicked header
			const header = event.currentTarget;

			// Toggle the active class on the header
			header.classList.toggle("active");

			// Find the corresponding content
			const content = header.nextElementSibling;

			// Toggle the display property
			if (content.style.display === "block") {
				content.style.display = "none";
				header.querySelector(".icon").classList.replace("fa-chevron-down", "fa-chevron-right");
			} else {
				content.style.display = "block";
				header.querySelector(".icon").classList.replace("fa-chevron-right", "fa-chevron-down");
			}
		},



	},

};