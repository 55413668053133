<template>
    <div>
        <div class="accordion">
            <div class="accordion-header"
                @click="toggleAccordion">
                <span>Alle Bestandteile unseres Eigentümer-Services im Überblick</span>
                <i class="fa-sharp fa-light fa-chevron-down icon"></i>
            </div>
            <div class="accordion-content"
                ref="content"
                style="display: none;">
                <ul class="tick-li">
                    <li>24h Buchbarkeit auf unserer Webseite</li>
                    <li>Verwaltung der Buchungen und Betreuung der Feriengäste</li>
                    <li>Werbung auf verschiedenen Buchungsplattformen</li>
                    <li>Zahlungsverwaltung</li>
                    <li>Schlüsselübergabe</li>
                    <li>Endreinigung</li>
                    <li>Gästebeitragskartenausstellung und Abrechnung mit der Kurverwaltung</li>
                    <li>Wäscheverleih</li>
                    <li>kleinere Hausmeisterarbeiten und Terminabsprache für Wartungsarbeiten</li>
                    <li>wenn gewünscht, Gartenservice und kleinere Instandsetzungsarbeiten</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    	voVueComponent: 'eig-accordeon',
    	methods: {
    		toggleAccordion(event) {
    			// Get the clicked header
    			const header = event.currentTarget;

    			// Toggle the active class on the header
    			header.classList.toggle("active");

    			// Find the corresponding content
    			const content = header.nextElementSibling;

    			// Toggle the display property
    			if (content.style.display === "block") {
    				content.style.display = "none";
    				header.querySelector(".icon").classList.replace("fa-chevron-up", "fa-chevron-down");
    			} else {
    				content.style.display = "block";
    				header.querySelector(".icon").classList.replace("fa-chevron-down", "fa-chevron-up");
    			}
    		},
    	},
    };
</script>

<style>
    /*  .accordion {
    	width: 100%;
    	max-width: 600px;
    	margin: 20px auto;
    	border: 1px solid #ddd;
    	border-radius: 5px;
    	overflow: hidden;
    }

    .accordion-header {
    	display: flex;
    	justify-content: space-between;
    	align-items: center;
    	padding: 15px;
    	background-color: #f7f7f7;
    	cursor: pointer;
    	border-bottom: 1px solid #ddd;
    	font-size: 18px;
    }

    .accordion-header:hover {
    	background-color: #e0e0e0;
    }

    .accordion-content {
    	display: none;
    	padding: 15px;
    	background-color: #fff;
    	border-top: 1px solid #ddd;
    }

    .accordion-header .icon {
    	font-size: 20px;
    	transition: transform 0.3s ease;
    }

    .accordion-header.active .icon {
    	transform: rotate(90deg);
    } */
</style>