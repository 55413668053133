var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "accordion" }, [
      _c(
        "div",
        { staticClass: "accordion-header", on: { click: _vm.toggleAccordion } },
        [
          _c("span", [
            _vm._v("Alle Bestandteile unseres Eigentümer-Services im Überblick")
          ]),
          _vm._v(" "),
          _c("i", { staticClass: "fa-sharp fa-light fa-chevron-down icon" })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "content",
          staticClass: "accordion-content",
          staticStyle: { display: "none" }
        },
        [_vm._m(0)]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "tick-li" }, [
      _c("li", [_vm._v("24h Buchbarkeit auf unserer Webseite")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Verwaltung der Buchungen und Betreuung der Feriengäste")
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Werbung auf verschiedenen Buchungsplattformen")]),
      _vm._v(" "),
      _c("li", [_vm._v("Zahlungsverwaltung")]),
      _vm._v(" "),
      _c("li", [_vm._v("Schlüsselübergabe")]),
      _vm._v(" "),
      _c("li", [_vm._v("Endreinigung")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Gästebeitragskartenausstellung und Abrechnung mit der Kurverwaltung"
        )
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Wäscheverleih")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "kleinere Hausmeisterarbeiten und Terminabsprache für Wartungsarbeiten"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "wenn gewünscht, Gartenservice und kleinere Instandsetzungsarbeiten"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }